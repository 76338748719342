
.tutorial-slider.slick-slider{
    display: flex;
    align-items: center;
    height: 100%;

    .slick-arrow.slick-prev, .slick-arrow.slick-next{
        display: none !important;
    }
    
    .slick-dots {
        bottom: 2rem;
    }

    .slick-dots li button::before {
        opacity: .25;
        color: black;
        font-size: .75rem;
    }

    .slick-dots li.slick-active button::before {
        opacity: .50;
        color: black;
        font-size: 1rem;
    }

}
